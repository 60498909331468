/** @jsx jsx */
import { jsx } from 'theme-ui'
import { Link } from "gatsby"
import Layout from "../../../components/layout"
import SEO from "../../../components/seo"
import { RiPrinterLine, RiDownloadLine, RiArrowLeftLine, RiCheckLine, RiUserLine, RiTeamLine, RiBriefcaseLine, RiCalendarCheckLine, RiFileTextLine, RiTimeLine, RiLightbulbLine, RiArrowRightSLine, RiShieldCheckLine } from "react-icons/ri"

const TrainingPlanTemplatePage = () => {
  
  // Function to handle printing the template
  const handlePrint = () => {
    if (typeof window !== 'undefined') {
      window.print();
    }
  }
  
  return (
    <Layout>
      <SEO 
        title="Role-Specific Training Plan Template | Structured Employee Development Framework"
        description="Download our customizable role-specific training plan template to create structured learning paths for different positions in your organization."
        keywords={[
          "role-specific training plan",
          "employee development template",
          "job training framework",
          "onboarding training plan",
          "skill development roadmap",
          "employee learning path",
          "competency development plan",
          "structured training template",
          "role-based learning plan",
          "training curriculum template"
        ]}
      />
      
      {/* Hero Section */}
      <div sx={{
        background: 'linear-gradient(135deg, #f8f9fa 0%, #e9ecef 100%)',
        py: [4, 5],
        position: 'relative'
      }}>
        <div sx={{
          maxWidth: '1200px',
          mx: 'auto',
          px: 3
        }}>
          <div sx={{
            maxWidth: '800px',
            mx: 'auto'
          }}>
            <Link 
              to="/guides/employee-onboarding" 
              sx={{
                display: 'inline-flex',
                alignItems: 'center',
                color: 'primary',
                fontSize: '0.9rem',
                textDecoration: 'none',
                mb: 3,
                '&:hover': {
                  textDecoration: 'underline'
                }
              }}
            >
              <RiArrowLeftLine sx={{ mr: 1 }} /> Back to Employee Onboarding Guide
            </Link>
            
            <h1 sx={{
              fontSize: ['1.8rem', '2.2rem'],
              fontWeight: 700,
              mb: 3,
              color: 'primary'
            }}>
              Role-Specific Training Plan Template
            </h1>
            
            <p sx={{
              fontSize: '1rem',
              lineHeight: 1.5,
              mb: 4,
              color: 'text',
              opacity: 0.9
            }}>
              A customizable framework for creating structured learning paths for different roles in your organization.
              This template helps ensure consistent skill development and faster time-to-productivity for new and existing employees.
            </p>
            
            <div sx={{
              display: 'flex',
              flexWrap: 'wrap',
              gap: 3,
              mb: 4
            }}>
              <button 
                onClick={handlePrint}
                type="button"
                sx={{
                  display: 'inline-flex',
                  alignItems: 'center',
                  gap: 2,
                  bg: 'primary',
                  color: 'white',
                  py: 2,
                  px: 3,
                  borderRadius: '6px',
                  fontSize: '0.95rem',
                  fontWeight: 600,
                  border: 'none',
                  cursor: 'pointer',
                  transition: 'transform 0.2s ease, box-shadow 0.2s ease',
                  boxShadow: '0 4px 10px rgba(0,0,0,0.1)',
                  '&:hover': {
                    transform: 'translateY(-2px)',
                    boxShadow: '0 6px 15px rgba(0,0,0,0.15)'
                  }
                }}
              >
                <RiPrinterLine /> Print Template
              </button>
              
              <Link 
                to="/contact"
                sx={{
                  display: 'inline-flex',
                  alignItems: 'center',
                  gap: 2,
                  bg: 'white',
                  color: 'primary',
                  py: 2,
                  px: 3,
                  borderRadius: '6px',
                  fontSize: '0.95rem',
                  fontWeight: 600,
                  textDecoration: 'none',
                  border: '2px solid',
                  borderColor: 'primary',
                  transition: 'transform 0.2s ease, box-shadow 0.2s ease',
                  boxShadow: '0 4px 10px rgba(0,0,0,0.05)',
                  '&:hover': {
                    transform: 'translateY(-2px)',
                    boxShadow: '0 6px 15px rgba(0,0,0,0.1)'
                  }
                }}
              >
                <RiDownloadLine /> Request Customizable Template
              </Link>
            </div>
          </div>
        </div>
      </div>
      
      {/* Main content will be added in subsequent edits */}
      
      {/* Main Content */}
      <div sx={{
        maxWidth: '1200px',
        mx: 'auto',
        px: 3,
        py: [4, 5],
        "@media print": {
          maxWidth: "100%",
          p: 0
        }
      }}>
        <div sx={{
          maxWidth: '800px',
          mx: 'auto'
        }}>
          {/* Introduction */}
          <section sx={{ mb: 5 }}>
            <h2 sx={{ 
              fontSize: ['1.5rem', '1.8rem'],
              fontWeight: 700,
              mb: 3,
              color: 'primary',
              borderBottom: '2px solid',
              borderColor: 'muted',
              pb: 2
            }}>
              Why Create Role-Specific Training Plans?
            </h2>
            
            <p sx={{ mb: 4, lineHeight: 1.6 }}>
              Role-specific training plans provide a structured approach to employee development, ensuring that 
              each team member acquires the specific skills, knowledge, and competencies needed for their position.
              Research shows that organizations with structured training programs experience 218% higher income per 
              employee and 24% higher profit margins compared to those without formalized training.
            </p>
            
            <div sx={{
              display: 'grid',
              gridTemplateColumns: ['1fr', '1fr 1fr'],
              gap: 4,
              mb: 4
            }}>
              {[
                {
                  title: "Faster Time to Proficiency",
                  description: "Reduce the time it takes for new employees to reach full productivity in their roles",
                  icon: <RiTimeLine />
                },
                {
                  title: "Consistent Skill Development",
                  description: "Ensure all employees in the same role develop the same core competencies",
                  icon: <RiUserLine />
                },
                {
                  title: "Clear Development Pathways",
                  description: "Provide visible career progression routes for employees to advance",
                  icon: <RiArrowRightSLine />
                },
                {
                  title: "Improved Quality & Performance",
                  description: "Enhance work quality and performance through targeted skill building",
                  icon: <RiShieldCheckLine />
                }
              ].map((benefit, index) => (
                <div key={`benefit-${index}`} sx={{
                  p: 3,
                  borderRadius: '8px',
                  boxShadow: '0 2px 15px rgba(0,0,0,0.06)',
                  display: 'flex',
                  flexDirection: 'column'
                }}>
                  <div sx={{ 
                    display: 'flex', 
                    alignItems: 'flex-start',
                    mb: 2
                  }}>
                    <span sx={{ 
                      color: 'primary', 
                      fontSize: '1.5rem',
                      mr: 2,
                      lineHeight: 1
                    }}>
                      {benefit.icon}
                    </span>
                    <h3 sx={{ 
                      fontSize: '1.1rem',
                      fontWeight: 600,
                      m: 0,
                      lineHeight: 1.3
                    }}>
                      {benefit.title}
                    </h3>
                  </div>
                  <p sx={{ 
                    fontSize: '0.95rem',
                    m: 0,
                    lineHeight: 1.5 
                  }}>
                    {benefit.description}
                  </p>
                </div>
              ))}
            </div>
          </section>
          
          {/* Core components section will go here */}
          
          {/* Core Components Section */}
          <section sx={{ mb: 5 }}>
            <h2 sx={{ 
              fontSize: ['1.5rem', '1.8rem'],
              fontWeight: 700,
              mb: 3,
              color: 'primary',
              borderBottom: '2px solid',
              borderColor: 'muted',
              pb: 2
            }}>
              Core Components of an Effective Training Plan
            </h2>
            
            <p sx={{ mb: 4, lineHeight: 1.6 }}>
              An effective role-specific training plan combines various learning components to create a comprehensive
              development experience. Include these key elements in your training plans to ensure employees receive 
              well-rounded development that addresses all aspects of their role.
            </p>
            
            <div sx={{
              mb: 4,
              borderRadius: '8px',
              overflow: 'hidden',
              boxShadow: '0 2px 15px rgba(0,0,0,0.06)'
            }}>
              {/* Role Profile */}
              <div>
                <h3 sx={{
                  m: 0,
                  p: 3,
                  bg: 'primary',
                  color: 'white',
                  fontSize: '1.1rem',
                  fontWeight: 600
                }}>
                  <span sx={{ display: 'inline-flex', alignItems: 'center' }}>
                    <RiUserLine sx={{ mr: 2 }} /> Role Profile & Competency Framework
                  </span>
                </h3>
                
                <div sx={{ p: 3, bg: 'white' }}>
                  <p sx={{ fontSize: '0.95rem', mb: 3, lineHeight: 1.6 }}>
                    Start with a clear definition of the role and the competencies required for success. This serves as 
                    the foundation for all training activities and establishes measurable performance standards.
                  </p>
                  
                  <ul sx={{ 
                    listStyle: 'none',
                    p: 0,
                    m: 0
                  }}>
                    {[
                      "Role description and responsibilities",
                      "Required technical skills and knowledge areas",
                      "Essential soft skills and behaviors",
                      "Key performance indicators",
                      "Proficiency levels for each competency (beginner to expert)"
                    ].map((item, index) => (
                      <li key={`profile-item-${index}`} sx={{ 
                        display: 'flex',
                        alignItems: 'flex-start',
                        py: 2,
                        borderBottom: index !== 4 ? '1px solid' : 'none',
                        borderColor: 'muted'
                      }}>
                        <span sx={{ color: 'primary', mr: 2, mt: '3px', flexShrink: 0 }}>
                          <RiCheckLine />
                        </span>
                        <span sx={{ fontSize: '0.95rem' }}>{item}</span>
                      </li>
                    ))}
                  </ul>
                </div>
              </div>
              
              {/* Learning Pathways */}
              <div sx={{ borderTop: '1px solid', borderColor: 'muted' }}>
                <h3 sx={{
                  m: 0,
                  p: 3,
                  bg: 'primary',
                  color: 'white',
                  fontSize: '1.1rem',
                  fontWeight: 600
                }}>
                  <span sx={{ display: 'inline-flex', alignItems: 'center' }}>
                    <RiArrowRightSLine sx={{ mr: 2 }} /> Structured Learning Pathways
                  </span>
                </h3>
                
                <div sx={{ p: 3, bg: 'white' }}>
                  <p sx={{ fontSize: '0.95rem', mb: 3, lineHeight: 1.6 }}>
                    Organize training into logical, progressive modules that build on each other. This creates a clear
                    development journey from basic concepts to advanced applications.
                  </p>
                  
                  <ul sx={{ 
                    listStyle: 'none',
                    p: 0,
                    m: 0
                  }}>
                    {[
                      "Progressive skill development sequence",
                      "Prerequisite knowledge requirements",
                      "Time estimates for each learning module",
                      "Mix of theoretical and practical elements",
                      "Clear milestones and progress indicators"
                    ].map((item, index) => (
                      <li key={`pathway-item-${index}`} sx={{ 
                        display: 'flex',
                        alignItems: 'flex-start',
                        py: 2,
                        borderBottom: index !== 4 ? '1px solid' : 'none',
                        borderColor: 'muted'
                      }}>
                        <span sx={{ color: 'primary', mr: 2, mt: '3px', flexShrink: 0 }}>
                          <RiCheckLine />
                        </span>
                        <span sx={{ fontSize: '0.95rem' }}>{item}</span>
                      </li>
                    ))}
                  </ul>
                </div>
              </div>
              
              {/* Learning Methods */}
              <div sx={{ borderTop: '1px solid', borderColor: 'muted' }}>
                <h3 sx={{
                  m: 0,
                  p: 3,
                  bg: 'primary',
                  color: 'white',
                  fontSize: '1.1rem',
                  fontWeight: 600
                }}>
                  <span sx={{ display: 'inline-flex', alignItems: 'center' }}>
                    <RiLightbulbLine sx={{ mr: 2 }} /> Diverse Learning Methods
                  </span>
                </h3>
                
                <div sx={{ p: 3, bg: 'white' }}>
                  <p sx={{ fontSize: '0.95rem', mb: 3, lineHeight: 1.6 }}>
                    Incorporate a variety of learning approaches to accommodate different learning styles and
                    reinforcement needs. This blended approach leads to better retention and skill application.
                  </p>
                  
                  <ul sx={{ 
                    listStyle: 'none',
                    p: 0,
                    m: 0
                  }}>
                    {[
                      "Formal training (classroom/virtual instructor-led)",
                      "Online self-paced courses and resources",
                      "On-the-job training and shadowing opportunities",
                      "Coaching and mentoring arrangements",
                      "Project-based learning and practical applications"
                    ].map((item, index) => (
                      <li key={`method-item-${index}`} sx={{ 
                        display: 'flex',
                        alignItems: 'flex-start',
                        py: 2,
                        borderBottom: index !== 4 ? '1px solid' : 'none',
                        borderColor: 'muted'
                      }}>
                        <span sx={{ color: 'primary', mr: 2, mt: '3px', flexShrink: 0 }}>
                          <RiCheckLine />
                        </span>
                        <span sx={{ fontSize: '0.95rem' }}>{item}</span>
                      </li>
                    ))}
                  </ul>
                </div>
              </div>
              
              {/* Assessment & Feedback */}
              <div sx={{ borderTop: '1px solid', borderColor: 'muted' }}>
                <h3 sx={{
                  m: 0,
                  p: 3,
                  bg: 'primary',
                  color: 'white',
                  fontSize: '1.1rem',
                  fontWeight: 600
                }}>
                  <span sx={{ display: 'inline-flex', alignItems: 'center' }}>
                    <RiFileTextLine sx={{ mr: 2 }} /> Assessment & Feedback Mechanisms
                  </span>
                </h3>
                
                <div sx={{ p: 3, bg: 'white' }}>
                  <p sx={{ fontSize: '0.95rem', mb: 3, lineHeight: 1.6 }}>
                    Integrate regular evaluation points to measure progress and provide constructive feedback.
                    This ensures accountability and allows for adjustments to the training approach as needed.
                  </p>
                  
                  <ul sx={{ 
                    listStyle: 'none',
                    p: 0,
                    m: 0
                  }}>
                    {[
                      "Knowledge checks and skills assessments",
                      "Performance observation checklists",
                      "Regular feedback and coaching sessions",
                      "Self-assessment opportunities",
                      "Certification or qualification milestones"
                    ].map((item, index) => (
                      <li key={`assessment-item-${index}`} sx={{ 
                        display: 'flex',
                        alignItems: 'flex-start',
                        py: 2,
                        borderBottom: index !== 4 ? '1px solid' : 'none',
                        borderColor: 'muted'
                      }}>
                        <span sx={{ color: 'primary', mr: 2, mt: '3px', flexShrink: 0 }}>
                          <RiCheckLine />
                        </span>
                        <span sx={{ fontSize: '0.95rem' }}>{item}</span>
                      </li>
                    ))}
                  </ul>
                </div>
              </div>
            </div>
          </section>
          
          {/* Sample training plan section will go here */}
          
          {/* Sample Training Plan Template */}
          <section sx={{ mb: 5 }}>
            <h2 sx={{ 
              fontSize: ['1.5rem', '1.8rem'],
              fontWeight: 700,
              mb: 3,
              color: 'primary',
              borderBottom: '2px solid',
              borderColor: 'muted',
              pb: 2
            }}>
              Sample Training Plan Template
            </h2>
            
            <p sx={{ mb: 4, lineHeight: 1.6 }}>
              Below is a sample structure for a role-specific training plan. This template can be customized for any
              position in your organization by adapting the learning objectives, content, and timeline to match the 
              specific role requirements.
            </p>
            
            <div sx={{
              border: '1px solid',
              borderColor: 'muted',
              borderRadius: '8px',
              overflow: 'hidden',
              boxShadow: '0 2px 15px rgba(0,0,0,0.06)',
              mb: 4
            }}>
              <div sx={{
                p: 3,
                bg: 'primary',
                color: 'white'
              }}>
                <h3 sx={{
                  fontSize: '1.2rem',
                  fontWeight: 600,
                  m: 0
                }}>
                  Role-Specific Training Plan
                </h3>
              </div>
              
              <div sx={{
                p: 4,
                bg: 'white'
              }}>
                {/* Form Header */}
                <div sx={{
                  mb: 4,
                  pb: 3,
                  borderBottom: '1px solid',
                  borderColor: 'muted'
                }}>
                  <div sx={{
                    display: 'grid',
                    gridTemplateColumns: ['1fr', '1fr 1fr'],
                    gap: 3,
                    mb: 3
                  }}>
                    <div>
                      <p sx={{ fontSize: '0.9rem', fontWeight: 600, mb: 1 }}>Employee Name:</p>
                      <div sx={{ 
                        height: '30px', 
                        borderBottom: '1px solid', 
                        borderColor: 'muted',
                        bg: '#f8f9fa'
                      }}></div>
                    </div>
                    
                    <div>
                      <p sx={{ fontSize: '0.9rem', fontWeight: 600, mb: 1 }}>Position/Role:</p>
                      <div sx={{ 
                        height: '30px', 
                        borderBottom: '1px solid', 
                        borderColor: 'muted',
                        bg: '#f8f9fa'
                      }}></div>
                    </div>
                  </div>
                  
                  <div sx={{
                    display: 'grid',
                    gridTemplateColumns: ['1fr', '1fr 1fr'],
                    gap: 3
                  }}>
                    <div>
                      <p sx={{ fontSize: '0.9rem', fontWeight: 600, mb: 1 }}>Department:</p>
                      <div sx={{ 
                        height: '30px', 
                        borderBottom: '1px solid', 
                        borderColor: 'muted',
                        bg: '#f8f9fa'
                      }}></div>
                    </div>
                    
                    <div>
                      <p sx={{ fontSize: '0.9rem', fontWeight: 600, mb: 1 }}>Training Period:</p>
                      <div sx={{ 
                        height: '30px', 
                        borderBottom: '1px solid', 
                        borderColor: 'muted',
                        bg: '#f8f9fa'
                      }}></div>
                    </div>
                  </div>
                </div>
                
                {/* Training Module Section */}
                <div sx={{ mb: 4 }}>
                  <h4 sx={{
                    fontSize: '1.1rem',
                    fontWeight: 600,
                    mb: 3,
                    color: 'primary'
                  }}>
                    Module 1: [Module Name]
                  </h4>
                  
                  <div sx={{
                    p: 3,
                    bg: '#f8f9fa',
                    borderRadius: '6px',
                    mb: 3
                  }}>
                    <div sx={{ mb: 3 }}>
                      <p sx={{ fontSize: '0.9rem', fontWeight: 600, mb: 1 }}>Learning Objectives:</p>
                      <ul sx={{
                        listStyle: 'none',
                        p: 0,
                        m: 0
                      }}>
                        {[
                          "Objective 1",
                          "Objective 2",
                          "Objective 3"
                        ].map((item, index) => (
                          <li key={`objective-${index}`} sx={{
                            mb: 1,
                            fontSize: '0.9rem',
                            lineHeight: 1.5,
                            display: 'flex',
                            alignItems: 'flex-start'
                          }}>
                            <span sx={{
                              color: 'primary',
                              mr: 2,
                              mt: '3px',
                              flexShrink: 0
                            }}>
                              <RiCheckLine size={14} />
                            </span>
                            {item}
                          </li>
                        ))}
                      </ul>
                    </div>
                    
                    <div sx={{ 
                      display: 'grid', 
                      gridTemplateColumns: ['1fr', '1fr 1fr'], 
                      gap: 3 
                    }}>
                      <div>
                        <p sx={{ fontSize: '0.9rem', fontWeight: 600, mb: 1 }}>Training Method:</p>
                        <div sx={{ fontSize: '0.9rem', color: 'text' }}>
                          [Instructor-led, Self-paced, On-the-job, etc.]
                        </div>
                      </div>
                      
                      <div>
                        <p sx={{ fontSize: '0.9rem', fontWeight: 600, mb: 1 }}>Time Allocation:</p>
                        <div sx={{ fontSize: '0.9rem', color: 'text' }}>
                          [Hours/Days required]
                        </div>
                      </div>
                    </div>
                  </div>
                  
                  <div sx={{ 
                    display: 'grid', 
                    gridTemplateColumns: ['1fr', '7fr 3fr'], 
                    gap: 3,
                    p: 3,
                    border: '1px solid',
                    borderColor: 'muted',
                    borderRadius: '6px'
                  }}>
                    <div>
                      <p sx={{ fontSize: '0.9rem', fontWeight: 600, mb: 1 }}>Resources/Materials:</p>
                      <ul sx={{
                        pl: 3,
                        mb: 0,
                        fontSize: '0.9rem'
                      }}>
                        <li>Resource 1</li>
                        <li>Resource 2</li>
                        <li>Resource 3</li>
                      </ul>
                    </div>
                    
                    <div>
                      <p sx={{ fontSize: '0.9rem', fontWeight: 600, mb: 1 }}>Assessment Method:</p>
                      <ul sx={{
                        pl: 3,
                        mb: 0,
                        fontSize: '0.9rem'
                      }}>
                        <li>Quiz</li>
                        <li>Practical Demo</li>
                      </ul>
                    </div>
                  </div>
                </div>
                
                {/* Signoff Section */}
                <div sx={{
                  mt: 5,
                  pt: 4,
                  borderTop: '1px solid',
                  borderColor: 'muted',
                  display: 'grid',
                  gridTemplateColumns: ['1fr', '1fr 1fr'],
                  gap: 4
                }}>
                  <div>
                    <p sx={{ fontSize: '0.9rem', fontWeight: 600, mb: 1 }}>Employee Signature:</p>
                    <div sx={{ 
                      height: '30px', 
                      borderBottom: '1px solid', 
                      borderColor: 'muted'
                    }}></div>
                    <p sx={{ fontSize: '0.9rem', fontWeight: 600, mt: 3, mb: 1 }}>Date:</p>
                    <div sx={{ 
                      height: '30px', 
                      borderBottom: '1px solid', 
                      borderColor: 'muted'
                    }}></div>
                  </div>
                  
                  <div>
                    <p sx={{ fontSize: '0.9rem', fontWeight: 600, mb: 1 }}>Manager Signature:</p>
                    <div sx={{ 
                      height: '30px', 
                      borderBottom: '1px solid', 
                      borderColor: 'muted'
                    }}></div>
                    <p sx={{ fontSize: '0.9rem', fontWeight: 600, mt: 3, mb: 1 }}>Date:</p>
                    <div sx={{ 
                      height: '30px', 
                      borderBottom: '1px solid', 
                      borderColor: 'muted'
                    }}></div>
                  </div>
                </div>
              </div>
            </div>
            
            <div sx={{
              display: 'flex',
              justifyContent: 'center',
              mt: 4
            }}>
              <Link 
                to="/contact"
                sx={{
                  display: 'inline-flex',
                  alignItems: 'center',
                  gap: 2,
                  bg: 'primary',
                  color: 'white',
                  py: 2,
                  px: 4,
                  borderRadius: '6px',
                  fontSize: '1rem',
                  fontWeight: 600,
                  textDecoration: 'none',
                  transition: 'transform 0.2s ease, box-shadow 0.2s ease',
                  boxShadow: '0 4px 10px rgba(0,0,0,0.1)',
                  '&:hover': {
                    transform: 'translateY(-2px)',
                    boxShadow: '0 6px 15px rgba(0,0,0,0.15)'
                  }
                }}
              >
                <RiDownloadLine /> Download Full Template Package
              </Link>
            </div>
          </section>
          
          {/* Implementation tips section will go here */}
          
          {/* Implementation Tips */}
          <section sx={{ mb: 5 }}>
            <h2 sx={{ 
              fontSize: ['1.5rem', '1.8rem'],
              fontWeight: 700,
              mb: 3,
              color: 'primary',
              borderBottom: '2px solid',
              borderColor: 'muted',
              pb: 2
            }}>
              Implementation Tips
            </h2>
            
            <div sx={{
              bg: '#f8f9fa',
              p: 4,
              borderRadius: '8px',
              boxShadow: '0 2px 15px rgba(0,0,0,0.06)'
            }}>
              <ul sx={{
                listStyle: 'none',
                p: 0,
                m: 0
              }}>
                {[
                  "Involve subject matter experts and experienced employees when creating role-specific training plans",
                  "Align training objectives with organizational goals and performance expectations",
                  "Break down complex skills into manageable learning modules with clear progression",
                  "Incorporate a mix of learning methods to address different learning styles",
                  "Set clear timelines and milestones to track progress throughout the training",
                  "Build in opportunities for practice, feedback, and reinforcement",
                  "Include regular check-ins and assessments to identify knowledge gaps",
                  "Update training plans regularly to reflect changing role requirements and technologies"
                ].map((tip, index) => (
                  <li key={`implementation-tip-${index}`} sx={{
                    display: 'flex',
                    alignItems: 'flex-start',
                    mb: 3,
                    fontSize: '0.95rem',
                    lineHeight: 1.5
                  }}>
                    <span sx={{
                      color: 'primary',
                      mr: 3,
                      mt: '3px',
                      flexShrink: 0
                    }}>
                      <RiCheckLine />
                    </span>
                    {tip}
                  </li>
                ))}
              </ul>
            </div>
          </section>
          
          {/* Related Resources */}
          <section>
            <h2 sx={{ 
              fontSize: ['1.5rem', '1.8rem'],
              fontWeight: 700,
              mb: 3,
              color: 'primary',
              borderBottom: '2px solid',
              borderColor: 'muted',
              pb: 2
            }}>
              Related Resources
            </h2>
            
            <div sx={{
              display: 'grid',
              gridTemplateColumns: ['1fr', null, 'repeat(2, 1fr)'],
              gap: 4
            }}>
              {[
                {
                  title: "New Employee Welcome Kit",
                  description: "Comprehensive package of orientation materials, company information, and first-day essentials.",
                  link: "/resources/templates/welcome-kit-template"
                },
                {
                  title: "Onboarding Process Checklist",
                  description: "Structured timeline of tasks for HR, managers, and new hires during the onboarding process.",
                  link: "/resources/templates/onboarding-checklist-template"
                },
                {
                  title: "Onboarding Experience Survey",
                  description: "Feedback form to evaluate and improve the onboarding process based on new hire experiences.",
                  link: "/contact"
                },
                {
                  title: "Employee Onboarding Guide",
                  description: "Comprehensive guide to effective employee onboarding best practices and strategies.",
                  link: "/guides/employee-onboarding"
                }
              ].map((resource, index) => (
                <Link 
                  key={`related-resource-${index}`}
                  to={resource.link}
                  sx={{
                    textDecoration: 'none',
                    color: 'text',
                    p: 3,
                    borderRadius: '8px',
                    boxShadow: '0 2px 15px rgba(0,0,0,0.06)',
                    transition: 'transform 0.2s ease, box-shadow 0.2s ease',
                    display: 'flex',
                    flexDirection: 'column',
                    bg: 'white',
                    '&:hover': {
                      transform: 'translateY(-3px)',
                      boxShadow: '0 6px 15px rgba(0,0,0,0.1)'
                    }
                  }}
                >
                  <h3 sx={{
                    fontSize: '1.1rem',
                    fontWeight: 600,
                    mb: 2,
                    color: 'primary'
                  }}>
                    {resource.title}
                  </h3>
                  
                  <p sx={{
                    fontSize: '0.95rem',
                    lineHeight: 1.5,
                    mb: 3,
                    flexGrow: 1
                  }}>
                    {resource.description}
                  </p>
                  
                  <div sx={{
                    display: 'flex',
                    alignItems: 'center',
                    fontSize: '0.9rem',
                    fontWeight: 600,
                    color: 'primary',
                    mt: 'auto'
                  }}>
                    {resource.link === "/contact" ? "Request Template" : "View Template"} <RiArrowRightSLine sx={{ ml: 1 }} />
                  </div>
                </Link>
              ))}
            </div>
          </section>
          
        </div>
      </div>
      
    </Layout>
  )
}

export default TrainingPlanTemplatePage 